import { PortfolioDescription } from "../molecules/PortfolioDescription";
import { PortfolioTitle } from "../molecules/PortfolioTitle";
import { PortfolioList } from "../molecules/PortfolioList";
import "../styles/Portfolio.css";
import { useEffect } from "react";
import { setContactsButtonVisual } from "../../../App";
import { Helmet } from "react-helmet";

export const Portfolio = () => {
  useEffect(() => {
    setContactsButtonVisual(true);
  }, []);
  return (
    <div className="Portfolio">
       <Helmet>
        <title>АБС - Портфолио</title>
        <meta
          name="description"
          content="Разработка современных мобильных приложений. Портфолио с работами нашей команды."
        />
      </Helmet>
      <PortfolioTitle text={"Портфолио"} />
      <PortfolioDescription
        text={
          "Максимальное погружение в сферу Вашего бизнеса для предоставления детально проработанного решения."
        }
      />
      <PortfolioList />
    </div>
  );
};
