import { useStore } from "effector-react"
import { OfferItem } from "../Atoms/OfferItem"
import { $offerList } from "../Logics/OfferLoad"
import '../Styles/OfferList.css'

export const OfferList = () => {
    var offerList = useStore($offerList)
    return (
        <div className="OfferList">
            <span>Мы предлагаем</span>
            <div className="OfferList__Group">
                {
                    offerList.map((e, i) => (
                        <OfferItem key={i} icon={e.icon} text={e.text} iconWeb={e.iconWeb}></OfferItem>
                    ))}
            </div>
        </div>
    )
}