import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

export const accessTokenName = "accessTokenV3";
export const refreshTokenName = "refreshTokenV3";
export const deffURL = "https://izba.kras-abs.ru";

// Общий Axios Instance для вызова Api
export const axiosInstance = axios.create({
  baseURL: "https://izba.kras-abs.ru/api/cp/",
  headers: {
    authorization: `Bearer ${localStorage.getItem(accessTokenName)}`,
  },
});

interface IquerySendMail {
  contact: string;
  name: string;
  text: string;
  email?: string;
  file?: any;
  Vacancy?: string;
}
export const querySendMail = async (params: IquerySendMail) => {
  return axiosInstance
    .post<{}>("sys/appRequest", {
      contact: params.contact.toString(),
      name: params.name.toString(),
      text: params.text.toString(),
    })
    .then((response) => {
      console.log(response.data);
      toast("Мы с вами свяжемся :)");
    })
    .catch((err) => {
      toast.error("Что то пошло не так...");
      console.log(err);
    });
};

export const querySendMailVacancy = async (params: IquerySendMail) => {
  const formData = new FormData();
  formData.append("contact", params.contact.toString());
  formData.append("name", params.name.toString());
  formData.append("text", params.text.toString());
  formData.append("email", params.email!.toString());
  formData.append("vacancy", params.Vacancy!.toString());
  params.file && formData.append("file", params.file[0]!);

  return axiosInstance
    .post<{}>("sys/appRequestVacancy", formData)
    .then((response) => {
      console.log(response.data);
      toast("Мы с вами свяжемся :)");
    })
    .catch((err) => {
      console.log(err);
      toast.error("Что то пошло не так...");
    });
};
