import { useState, useEffect } from "react";
import { useWindowSize } from "@react-hook/window-size";
import Image from "react-image-webp";
import "../styles/ProjectCasesNew.css";
import "../styles/Phone.css";

import JKH_1 from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(1).png";
import JKH_2 from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(2).png";
import JKH_3 from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle.png";
import PLZ_1 from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(5).png";
import PLZ_2 from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(4).png";
import PLZ_3 from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(3).png";
import JKH_ICON from "../../../common/assets/MainPage/ProjectCasesNew/Group 6.png";
import PLZ_ICON from "../../../common/assets/MainPage/ProjectCasesNew/Лого1 2.png";
import BlobSmall from "../../../common/assets/MainPage/ProjectCasesNew/Union(2).png";
import Apple_logo from "../../../common/assets/MainPage/ProjectCasesNew/apple.png";
import Android_logo from "../../../common/assets/MainPage/ProjectCasesNew/android-logo.png";
import circle from "../../../common/assets/MainPage/ProjectCasesNew/Union.png";

import JKH_1Web from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(1).webp";
import JKH_2Web from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(2).webp";
import JKH_3Web from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle.webp";
import PLZ_1Web from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(5).webp";
import PLZ_2Web from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(4).webp";
import PLZ_3Web from "../../../common/assets/MainPage/ProjectCasesNew/Rectangle(3).webp";
import JKH_ICONWeb from "../../../common/assets/MainPage/ProjectCasesNew/Group 6.webp";
import PLZ_ICONWeb from "../../../common/assets/MainPage/ProjectCasesNew/Лого1 2.webp";
import BlobSmallWeb from "../../../common/assets/MainPage/ProjectCasesNew/Union(2).webp";
import Apple_logoWeb from "../../../common/assets/MainPage/ProjectCasesNew/apple.webp";
import Android_logoWeb from "../../../common/assets/MainPage/ProjectCasesNew/android-logo.webp";
import circleWeb from "../../../common/assets/MainPage/ProjectCasesNew/Union.webp";

import { OrangeButton } from "../../../common/ui/OrangeButton/organoids/OrangeButton";
import { pathPortfolio } from "../../../common/config/path";

export const ProjectCasesNew = () => {
  const [width, height] = useWindowSize();
  const [phone1, setPhone1] = useState("jkh_phone1_fly");
  const [phone2, setPhone2] = useState("jkh_phone2_fly");
  const [phone3, setPhone3] = useState("jkh_phone3_fly");
  const [phone_PLZ1, setPhone_PLZ1] = useState("");
  const [phone_PLZ2, setPhone_PLZ2] = useState("");
  const [phone_PLZ3, setPhone_PLZ3] = useState("");
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (height > width) {
      setPhone1(position > 1300 ? "jkh_phone1" : "jkh_phone1_fly");
      setPhone2(position > 1300 ? "jkh_phone2" : "jkh_phone2_fly");
      setPhone3(position > 1300 ? "jkh_phone3" : "jkh_phone3_fly");
      setPhone_PLZ1(position > 1600 ? "plz_phone1" : "plz_phone1_fly");
      setPhone_PLZ2(position > 1600 ? "plz_phone2" : "plz_phone2_fly");
      setPhone_PLZ3(position > 1600 ? "plz_phone3" : "plz_phone3_fly");
    } else {
      setPhone1(position > 1800 ? "jkh_phone1" : "jkh_phone1_fly");
      setPhone2(position > 1800 ? "jkh_phone2" : "jkh_phone2_fly");
      setPhone3(position > 1800 ? "jkh_phone3" : "jkh_phone3_fly");
      setPhone_PLZ1(position > 2200 ? "plz_phone1" : "plz_phone1_fly");
      setPhone_PLZ2(position > 2200 ? "plz_phone2" : "plz_phone2_fly");
      setPhone_PLZ3(position > 2200 ? "plz_phone3" : "plz_phone3_fly");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="ProjectCasesNew">
      <div className="ProjectCasesNew__Content">
        <span>
          <span style={{ color: "coral" }}>Наши </span> работы
        </span>
        <div className="ProjectCasesNew__Content__Group">
          <div className="ProjectCasesNew__Content__Group__Item">
            <div className="ProjectCasesNew__Content__Group__Item__Images">
              <Image
                className={phone1}
                title="ЖКХ.Инфо"
                alt="ЖКХ.Инфо"
                src={JKH_1}
                webp={JKH_1Web}
              />
              <Image
                className={phone2}
                title="ЖКХ.Инфо"
                alt="ЖКХ.Инфо"
                src={JKH_2}
                webp={JKH_2Web}
              />
              <Image
                className={phone3}
                title="ЖКХ.Инфо"
                alt="ЖКХ.Инфо"
                src={JKH_3}
                webp={JKH_3Web}
              />
              <Image
                className={
                  "ProjectCasesNew__Content__Group__Item__Images__Blob"
                }
                title="ЖКХ.Инфо"
                alt="ЖКХ.Инфо"
                src={BlobSmall}
                webp={BlobSmallWeb}
              />
            </div>
            <div className="ProjectCasesNew__Content__Group__Item__Desk">
              <div className="ProjectCasesNew__Content__Group__Item__Desk__Title">
                <Image
                  title="ЖКХ.Инфо"
                  alt="ЖКХ.Инфо"
                  src={JKH_ICON}
                  webp={JKH_ICONWeb}
                />
                <span>ЖКХ.Инфо</span>
              </div>
              <noindex>
                <p>
                  Данный продукт - результат нескольких лет работы нашей команды
                  разработчиков. Продукт из разряда B2B2C. Продукт предназначен
                  для управляющих компаний (УК) и их жителей. Основное
                  назначение продукта: автоматизировать взаимодействие между
                  жителями и УК.
                </p>
              </noindex>
              <div className="ProjectCasesNew_icons">
                <div className="ProjectCasesNew_icons_and_button">
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=ru.krasabs.izba&hl=ru&gl=US"
                    >
                      <Image
                        className="ProjectCasesNew_link"
                        title="Android"
                        alt="Android"
                        src={Android_logo}
                        webp={Android_logoWeb}
                      />
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://apps.apple.com/ru/app/%D0%B6%D0%BA%D1%85-%D0%B8%D0%BD%D1%84%D0%BE/id1485202261"
                    >
                      <Image
                        className="ProjectCasesNew_link"
                        title="Apple"
                        alt="Apple"
                        src={Apple_logo}
                        webp={Apple_logoWeb}
                      />
                    </a>
                  </div>
                  <OrangeButton
                    className={"ConsultationRequest__Form__Button"}
                    link={"https://gkh-info.org/"}
                    title="Подробнее"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ProjectCasesNew__Content__Group__Item ProjectCasesNew__Content__Group__Item__Flipped">
            <div className="ProjectCasesNew__Content__Group__Item__Desk ProjectCasesNew__Content__Group__Item__Desk__Two">
              <div className="ProjectCasesNew__Content__Group__Item__Desk__Title">
                <Image
                  title="Платежка"
                  alt="Платежка"
                  src={PLZ_ICON}
                  webp={PLZ_ICONWeb}
                />
                <noindex>
                  <span>Платежка</span>
                </noindex>
              </div>
              <noindex>
                <p>
                  Мобильное приложение для оплаты услуг связи, ЖКХ и прочего.
                  Для красноярской финансовой организации, занимающейся
                  деятельностью по приему платежей физических лиц платежными
                  агентами.
                </p>
              </noindex>
              <div className="ProjectCasesNew_icons">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=ru.krasabs.oplat"
                >
                  <Image
                    className="ProjectCasesNew_link"
                    title="Android"
                    alt="Android"
                    src={Android_logo}
                    webp={Android_logoWeb}
                  />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/ru/app/%D0%BF%D0%BB%D0%B0%D1%82%D0%B5%D0%B6%D0%BA%D0%B0-online/id1561106292"
                >
                  <Image
                    className="ProjectCasesNew_link"
                    title="Apple"
                    alt="Apple"
                    src={Apple_logo}
                    webp={Apple_logoWeb}
                  />
                </a>
              </div>
            </div>
            <div className="ProjectCasesNew__Content__Group__Item__Images ProjectCasesNew__Content__Group__Item__Images__Two">
              <Image
                className={phone_PLZ1}
                title="Платежка"
                alt="Платежка"
                src={PLZ_1}
                webp={PLZ_1Web}
              />
              <Image
                className={phone_PLZ2}
                title="Платежка"
                alt="Платежка"
                src={PLZ_2}
                webp={PLZ_2Web}
              />
              <Image
                className={phone_PLZ3}
                title="Платежка"
                alt="Платежка"
                src={PLZ_3}
                webp={PLZ_3Web}
              />
              <Image
                className="ProjectCasesNew__Content__Group__Item__Images__Circle"
                title="Платежка"
                alt="Платежка"
                src={circle}
                webp={circleWeb}
              />
            </div>
          </div>
        </div>
        <OrangeButton
          link={pathPortfolio}
          className="ProjectCasesNew_btn"
          title="Посмотреть больше проектов"
        />
      </div>
    </div>
  );
};
