import { createEvent, restore } from "effector";
import Clients1 from '../../../common/assets/MainPage/ClientsList/client1.png'
import Clients2 from '../../../common/assets/MainPage/ClientsList/client2.png'
import Clients3 from '../../../common/assets/MainPage/ClientsList/client3.png'
import NonAuthor from "../../../common/assets/MainPage/ClientsList/NonAuthor.png"

import Clients1Web from '../../../common/assets/MainPage/ClientsList/client1.webp'
import Clients2Web from '../../../common/assets/MainPage/ClientsList/client2.webp'
import Clients3Web from '../../../common/assets/MainPage/ClientsList/client3.webp'
import NonAuthorWeb from "../../../common/assets/MainPage/ClientsList/NonAuthor.webp"

export interface IFeedBackItem {
  icon: string,
  iconWeb: string,
  text: string,
  author: string,
  aut_post: string,
  aut_icon: string
}

export const setFeedBackList = createEvent<IFeedBackItem[]>();
export const $feedBackList = restore<IFeedBackItem[]>(
  setFeedBackList,
  [{
    icon: Clients1,
    iconWeb:Clients1Web,
    aut_post: "Генеральный директор",
    aut_icon: NonAuthor,
    text: "Мы работаем с компанией АБС с 2017 года. За это время компания зарекомендовала себя как клиентоориентированный и надежный партнер. Специалисты всегда работают оперативно, качественно и учитывая требования заказчика.",
    author: "Яценко А.И.",

  },
  {
    icon: Clients2,
    iconWeb:Clients2Web,
    aut_post: "Директор",
    aut_icon: NonAuthor,
    text: "В процессе эксплуатации приложения все задачи на исправления и доработку выполняются вовремя, в строго обозначенные сроки. Радует и то, что специалисты всегда остаются на связи и своевременно оказывают техническую и клиентскую поддержку.",
    author: "Супрун С.А.",

  }
    ,
  {
    icon: Clients3,
    iconWeb:Clients3Web,
    aut_post: "Директор",
    aut_icon: NonAuthor,
    text: "«LionInvest» пользуется услугами и разработанным программным обеспечением с 2021 года. Рекомендуем ООО «АБС» как ответственного бизнес-партнёра, а её сотрудников как профессионалов своего дела.",
    author: "Попков И.В.",

  }
  ]
);