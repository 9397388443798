import { useEffect, useState } from "react";
import { setContactsButtonVisual } from "../../../App";
import { JobsText } from "../Atoms/JobsText";
import { OfferList } from "../Molecules/OfferList";
import { OpenJobs } from "../Molecules/OpenJobs";
import "../Styles/Jobs.css";
import { RequestJob } from "../Molecules/RequestJob";
import { Helmet } from "react-helmet";

export const Jobs = () => {
  const [VacancyToRequestJob, setVacancyToRequestJob] = useState<string>("");

  useEffect(() => {
    setContactsButtonVisual(true);
  }, []);

  useEffect(() => {
    console.log("VacancyToRequestJob", VacancyToRequestJob);
  }, [VacancyToRequestJob]);

  return (
    <div className="Jobs">
      <Helmet>
        <title>АБС - Вакансии</title>
        <meta
          name="description"
          content="Разработка современных мобильных приложений.
          Мы предлагаем обучение у лучших наставников-экспертов, надежную базу для старта, карьерный рост и профессиональное развитие."
        />
      </Helmet>
      <JobsText />
      <OfferList />
      <OpenJobs
        setVacancyToRequestJob={setVacancyToRequestJob}
        VacancyToRequestJob={VacancyToRequestJob}
      />
      {VacancyToRequestJob && (
        <RequestJob
          setVacancyToRequestJob={setVacancyToRequestJob}
          VacancyToRequestJob={VacancyToRequestJob}
        />
      )}
    </div>
  );
};
