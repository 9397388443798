import { useEffect, useState } from "react";
import { querySendMailVacancy } from "../../../common/sendMail";
import { OrangeButton } from "../../../common/ui/OrangeButton/organoids/OrangeButton";
import "../Styles/RequestJob.css";
import addFile from "../../../common/assets/addFile.svg";
import { toast } from "react-toastify";

interface IRequestJob {
  setVacancyToRequestJob: any;
  VacancyToRequestJob: any;
}

export const RequestJob = (params: IRequestJob) => {
  const [value, setValue] = useState({
    contact: "",
    name: "",
    text: "",
    file: null,
    email: "",
  });

  useEffect(() => {
    setValue({
      contact: "",
      name: "",
      text: "",
      file: null,
      email: "",
    });
  }, [params.VacancyToRequestJob]);

  const sendRequestAtom = () => {
    let errorText = "Не введены обязательные поля: ";
    if (value.contact.length === 0) {
      errorText += " Номер телефона";
    }
    if (value.name.length === 0) {
      errorText += " Имя";
    }
    if (value.email.length === 0) {
      errorText += " Email";
    }
    if (
      value.contact.length === 0 ||
      value.name.length === 0 ||
      value.email.length === 0
    )
      toast.error(errorText);

    if (
      value.contact.length !== 0 &&
      value.name.length !== 0 &&
      value.email.length !== 0
    ) {
      querySendMailVacancy({
        contact: value.contact,
        name: value.name,
        text: value.text,
        email: value.email,
        file: value.file,
        Vacancy: params.VacancyToRequestJob,
      });
      params.setVacancyToRequestJob("");
    }
  };

  const ChangePhoneEdit = (event: any) => {
    let val = event.target.value;
    val = val.replace(/ /gm, "").replace(/[^+\d]/g, "");
    console.log(val);

    let num = "";

    if (val[0] == 8) {
      num = `${val.substring(0, 1)} ${val.substring(1, 4)} ${val.substring(
        4,
        7
      )} ${val.substring(7, 9)} ${val.substring(9, 11)}`;
    } else if (val[0] == 7) {
      num = `+ ${val.substring(0, 1)} ${val.substring(1, 3)} ${val.substring(
        3,
        6
      )} ${val.substring(6, 9)} ${val.substring(9, 11)} ${val.substring(
        11,
        13
      )}`;
    } else if (val[0] == "+") {
      num = `${val.substring(0, 1)} ${val.substring(1, 2)} ${val.substring(
        2,
        5
      )} ${val.substring(5, 8)} ${val.substring(8, 10)} ${val.substring(
        10,
        12
      )}`;
    } else {
      num = val;
    }
    num = num.trim();
    setValue({ ...value, ["contact"]: num });
  };

  const ChangeNameEdit = (event: any) => {
    let val = event.target.value;
    val = val.replace(/[^a-zа-яё0-9\s]/gi, "").replace(/[0-9]/g, "");
    console.log(val);
    setValue({ ...value, ["name"]: val });
  };

  const handleChangeFile = (event: any) => {
    console.log(event.target.files);
    setValue({ ...value, ["file"]: event.target.files });
  };

  return (
    <div className="RequestJob">
      <div className="RequestJob__Block">
        <div className="RequestJob__Block__headers">
          <input
            value={value.name}
            onChange={ChangeNameEdit}
            className="RequestJob__Block__Name RequestJob__Block__Input"
            placeholder="Укажите Ваше Имя*"
          />
          <input
            value={value.contact}
            onChange={ChangePhoneEdit}
            className="RequestJob__Block__Phone RequestJob__Block__Input"
            placeholder="Укажите ваш телефон*"
          />
          <input
            value={value.email}
            onChange={(event) =>
              setValue({ ...value, ["email"]: event.target.value })
            }
            className="RequestJob__Block__Email RequestJob__Block__Input"
            placeholder="Укажите ваш email*"
          />
        </div>
        <textarea
          value={value.text}
          onChange={(event) =>
            setValue({ ...value, ["text"]: event.target.value })
          }
          className="RequestJob__Block__Text "
          placeholder="Расскажите о себе и своем опыте"
        />

        <div className="RequestJob__Block__ButtonAndFile">
          {/* <div style={{width:"20%"}}></div> */}
          <OrangeButton onCLick={sendRequestAtom} title="Отправить" />
          <label className="input-file">
            <input type="file" onChange={handleChangeFile} />
            <span className="input-file__blockImgAndText">
              <img src={addFile} alt="" />
              {value.file ? "Резюме прикреплено" : "Прикрепить резюме"}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};
