import { ContactsAtom } from "../atoms/ContactsAtom"
import { RequestAtom } from "../atoms/RequestAtom"

export const OurContacts = () => {
    return (
        <div className="OurContacts">
            <ContactsAtom />
            <RequestAtom />
        </div>
    )
}