import { OpenJobsItem } from "../Atoms/OpenJobsItem";
import Android from "../../../common/assets/Jobs/OpenJobsItem/Android.svg";
import IOS from "../../../common/assets/Jobs/OpenJobsItem/IOS.svg";
import React from "../../../common/assets/Jobs/OpenJobsItem/React.svg";
import UI from "../../../common/assets/Jobs/OpenJobsItem/UI.svg";
import Golang from "../../../common/assets/Jobs/OpenJobsItem/Golang.svg";
import { useState } from "react";

interface IOpenJobs {
  setVacancyToRequestJob: React.Dispatch<React.SetStateAction<string>>
  VacancyToRequestJob: string
}
interface IJobs {
  text: string
  img: string
}
export const OpenJobs = (params: IOpenJobs) => {
 const [jobs, setJobs] = useState<IJobs[]>([
    {
      text: `Android\nKotlin`,
      img: Android
    },
    {
      text: `iOS\nSwift`,
      img: IOS
    },
    {
      text: `Web\nReact`,
      img: React
    },
    {
      text: `UI/UX`,
      img: UI
    },
    {
      text: `Backend\nGolang`,
      img: Golang
    }
  ])
  return (
    <div className="OpenJobs">
      <span>Открытые вакансии</span>
      <div className="OpenJobs__Group">
        {
          jobs.map((e: IJobs) =>
            <OpenJobsItem
              text={e.text}
              img={e.img}
              VacancyToRequestJob={params.VacancyToRequestJob}
              setVacancyToRequestJob={params.setVacancyToRequestJob}
            />
          )
        }
      </div>
    </div>
  );
};
