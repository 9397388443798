import { useStore } from "effector-react";
import { NavLink } from "react-router-dom";
import Image from "react-image-webp";
import { $ProjectsLists } from "../../../common/Projects/ProjectsLists/ProjectsLists";
import "../styles/PortfolioList.css"

export const PortfolioList = () => {
    const ProjectsLists = useStore($ProjectsLists);

    return (
        <div className="PortfolioList">
            {ProjectsLists.map((e: any) =>
                <NavLink className="PortfolioList__Item" to={e.url}>
                    <Image
                        className="PortfolioList__Item__image"
                        title={e.long.title}
                        alt={e.long.title}
                        src={e.short.image}
                        webp={e.short.imageWeb}
                    />
                    <div className="PortfolioList__Item__description">
                        {e.short.description}
                    </div>
                </NavLink>
            )}
        </div>
    );
}
