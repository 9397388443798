import Image from "react-image-webp";
import { IClientLineItem } from "../logics/ClientsLineLoad";

export const ClientLineItem = (params: IClientLineItem) => {
    return (
        <div className="ClientLineItem" style={{ position: 'relative', height: "40px", margin: '40px' }}>
            <Image
                className="ClientLineItem__Color"
                // title="Icon"
                // alt="Icon"
                src={params.iconcolored}
                webp={params.iconcoloredWeb}
            />
            <Image
                className="ClientLineItem__Back"
                // title="Icon"
                // alt="Icon"
                src={params.icon}
                webp={params.iconWeb}
                onClick={()=>window.open(params.url)}
            />
        </div>
    )
}