import Carousel from "react-multi-carousel";
import '../styles/FeedBack.css'
import "react-multi-carousel/lib/styles.css";

import { FeedBackItem } from "../atoms/FeedBackItem"
import { IFeedBackItem } from '../logics/ClientFeedbackLoad'
import Bubble1 from '../../../common/assets/MainPage/ClientsList/Bubble1.svg'
import Bubble3 from '../../../common/assets/MainPage/ClientsList/Bubble2.svg'
interface IClientList {
    list: IFeedBackItem[]
}
export const ClientFeedback = ({ list }: IClientList) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1300, min: 900 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 900, min: 0 },
            items: 1
        }
    };
    return (
        <div className="ClientFeedback">
            <img src={Bubble1} alt="Bubble" className="ClientFeedback__Bubble__Top" />
            <img src={Bubble3} alt="Bubble" className="ClientFeedback__Bubble__Bottom" />
            <Carousel responsive={responsive} infinite={true}>
                {list.map((c, i) => (
                    <FeedBackItem key={i} icon={c.icon} iconWeb={c.iconWeb} text={c.text} author={c.author} aut_icon={c.aut_icon} aut_post={c.aut_post} />
                ))}
            </Carousel>
        </div>
    )
}