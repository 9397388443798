import { createEvent, restore } from "effector"
import { pathContacts, pathPortfolio, pathJobs, namePathContacts, namePathPortfolio, namePathJobs, pathMain, namePathMain } from '../../../config/path'

export interface IHeaderNavList {
    path: string,
    name: string,
    exact: boolean
}

export const setHeaderNavList = createEvent<IHeaderNavList[]>();
export const $headerNavList = restore<IHeaderNavList[]>(
    setHeaderNavList,
    [{ path: pathMain, name: namePathMain, exact: true },
    { path: pathPortfolio, name: namePathPortfolio, exact: true },
    { path: pathJobs, name: namePathJobs, exact: true },
    { path: pathContacts, name: namePathContacts, exact: true }]
);