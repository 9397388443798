import "./CookieAlert.css";
import CookieAlertImg from "../../assets/CookieAlert.png";

interface ICookieAlert {
  setVisualCookieAlert: React.Dispatch<React.SetStateAction<boolean>>;
  visualCookieAlert: boolean;
}
const CookieAlert = (params: ICookieAlert) => {
  const hideCookieAlert = () => {
    params.setVisualCookieAlert(false);
    localStorage.setItem("CookieAlert", "1");
  };
  return (
    <div
      className={
        params.visualCookieAlert
          ? "cookie-alert-container"
          : "hiden cookie-alert-container"
      }
    >
      <noindex>
        <div className="cookie-alertTextAndImg">
          <img src={CookieAlertImg}></img>
          <div className="cookie-alert-text">
            Мы используем cookie. Это позволяет нам анализировать взаимодействие
            посетителей с сайтом и делать его лучше. Продолжая пользоваться
            сайтом, Вы соглашаетесь с использованием файлов cookie.
          </div>
        </div>
      </noindex>
      <button className="cookie-alert-button" onClick={hideCookieAlert}>
        Хорошо
      </button>
    </div>
  );
};

export default CookieAlert;
