
interface IPortfolioDescription {
    text: string
}
export const PortfolioDescription = (params: IPortfolioDescription) => {
    return (
        <div className="PortfolioDescription">
            {params.text}
        </div>
    );
}
