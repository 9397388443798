import "../styles/Header.css";
import { useStore } from "effector-react";
import { NavLink } from "react-router-dom";
import { $headerNavList } from "../logics/effector";
import { HeaderNavList } from "../molecules/HeaderNavList";
import ABSLogo from "../../../assets/Header/ABSLogo.svg";
import { OrangeButton } from "../../OrangeButton/organoids/OrangeButton";
import { pathContacts, pathMain } from "../../../config/path";
import { MenuBar } from "../molecules/MenuBar";

export const Header = ({ContactsButtonVisual}:any) => {
  const headerNavList = useStore($headerNavList);

  return (
    <div className="Header">
      <NavLink to={pathMain}>
        <img src={ABSLogo} className="Header__Logo" alt="Logo"></img>
      </NavLink>
      <HeaderNavList
        list={headerNavList}
      />

      <OrangeButton
        className={
          ContactsButtonVisual
            ? "Header__OrangeButton"
            : "Header__OrangeButton Header__OrangeButton__noVisual"
        }
        link={pathContacts}
        title="Оставить заявку"
      />

      <MenuBar
        list={headerNavList}
      />
    </div>
  );
};
