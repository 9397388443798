import "../Styles/OpenJobsItem.css";

interface IOpenJobsItem {
  text: string;
  img: string;
  setVacancyToRequestJob: React.Dispatch<React.SetStateAction<string>>
  VacancyToRequestJob: string
}

export const OpenJobsItem = (params: IOpenJobsItem) => {
  return (
    <div
      className={
        params.VacancyToRequestJob !== params.text
          ? "OpenJobsItem"
          : "OpenJobsItem OpenJobsItem__hoverOnClick"
      }
      onClick={() => { params.VacancyToRequestJob !== params.text ? params.setVacancyToRequestJob(params.text) : params.setVacancyToRequestJob("") }}
    >
      <img src={params.img} alt="" />
      <div className="OpenJobsItem__Text">{params.text}</div>
    </div>
  );
};
