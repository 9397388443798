import { NavLink } from "react-router-dom"
import Image from "react-image-webp";
import { pathPortfolio } from "../../config/path"
import AppStore from "../assets/Stores/AppStore.png"
import GooglePlay from "../assets/Stores/GooglePlay.png"
import AppGallery from "../assets/Stores/AppGallery.png"
import RuStore from "../assets/Stores/RuStore.png"

import AppStoreWeb from "../assets/Stores/AppStore.webp"
import GooglePlayWeb from "../assets/Stores/GooglePlay.webp"
import AppGalleryWeb from "../assets/Stores/AppGallery.webp"
import RuStoreWeb from "../assets/Stores/RuStore.webp"

import Arrow from '../assets/Arrow.svg'
import "../Styles/ProjectsItem.css"

interface IProjectsItem {
    title?: string
    image?: string
    about?: string
    task?: string
    technologies?: any
    result?: string
    client?: string
    app?: any
    imageWeb?: any
}
export const ProjectsItem = (params: IProjectsItem) => {

    return (
        <div className="ProjectsItem">
            <NavLink className="ProjectsItem__Link" to={pathPortfolio} >
                <img src={Arrow} alt="" />
                Назад</NavLink>
            <div className="ProjectsItem__title">
                {params.title}
                <Image
                    className="ProjectsItem__image"
                    title={params.title}
                    alt={params.title}
                    src={params.image}
                    webp={params.imageWeb}
                />
            </div>
            {params.about && <>
                <div className="ProjectsItem__MinTitle">
                    О проекте
                </div>
                <div className="ProjectsItem__params ProjectsItem__about">
                    {params.about}
                </div>
            </>
            }
            <Image
                className="ProjectsItem__image__Phone"
                title={params.title}
                alt={params.title}
                src={params.image}
                webp={params.imageWeb}
            />
            {params.client && <>
                <div className="ProjectsItem__MinTitle">
                    О клиенте
                </div>
                <div className="ProjectsItem__params ProjectsItem__about">
                    {params.client}
                </div>
            </>
            }
            {params.task && <>
                <div className="ProjectsItem__MinTitle">
                    Задача
                </div>
                <div className="ProjectsItem__params ProjectsItem__task">
                    {params.task}
                </div>
            </>
            }
            {params.technologies.length !== 0 && <>
                <div className="ProjectsItem__MinTitle">
                    Технологии
                </div>
                <div className="ProjectsItem__params ProjectsItem__technologies">
                    {params.technologies.map((a: any, id: any) =>
                        <div key={id} className="ProjectsItem__technologies__Item">
                            <Image
                                className="ProjectsItem__technologies__Item__Image"
                                title={a.title}
                                alt={a.title}
                                src={a.image}
                                webp={a.imageWeb}
                            />
                            <div className="ProjectsItem__technologies__Item__Title">
                                {a.title}
                            </div>
                        </div>
                    )}
                </div>
            </>
            }
            {params.result && <>
                <div className="ProjectsItem__MinTitle">
                    Результат
                </div>
                <div className="ProjectsItem__params ProjectsItem__result">
                    {params.result}
                </div>
            </>
            }
            {params.app.image && <>
                <div className="ProjectsItem__app">
                    <Image
                        className="ProjectsItem__app__image"
                        title={params.title}
                        alt={params.title}
                        src={params.app.image}
                        webp={params.app.imageWeb}
                    />
                    <div className="ProjectsItem__app__title">
                        Приложение в маркетплейсах
                    </div>
                    <div className="ProjectsItem__app__items">
                        {params.app.appStore &&
                            <NavLink target="_blank" to={params.app.appStore}>
                                <Image
                                    className="ProjectsItem__app__items__image"
                                    title="AppStore"
                                    alt="AppStore"
                                    src={AppStore}
                                    webp={AppStoreWeb}
                                />
                            </NavLink>
                        }
                        {params.app.playMarket &&
                            <NavLink target="_blank" to={params.app.playMarket}>
                                <Image
                                    className="ProjectsItem__app__items__image"
                                    title="GooglePlay"
                                    alt="GooglePlay"
                                    src={GooglePlay}
                                    webp={GooglePlayWeb}
                                />
                            </NavLink>
                        }
                        {params.app.appGallery &&
                            <NavLink target="_blank" to={params.app.appGallery}>
                                <Image
                                    className="ProjectsItem__app__items__image"
                                    title="AppGallery"
                                    alt="AppGallery"
                                    src={AppGallery}
                                    webp={AppGalleryWeb}
                                />
                            </NavLink>
                        }
                        {params.app.RuStore &&
                            <NavLink target="_blank" to={params.app.RuStore}>
                                <Image
                                    className="ProjectsItem__app__items__image"
                                    title="RuStore"
                                    alt="RuStore"
                                    src={RuStore}
                                    webp={RuStoreWeb}
                                />
                            </NavLink>
                        }
                    </div>
                </div>
            </>
            }


        </div>
    );
}
