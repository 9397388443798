import "../styles/FeedBack.css";
import Image from "react-image-webp";
import FooterLine from "../../../common/assets/MainPage/ClientsList/FooterLine.png";
import FooterLineWeb from "../../../common/assets/MainPage/ClientsList/FooterLine.webp";
import { IFeedBackItem } from "../logics/ClientFeedbackLoad";

export const FeedBackItem = (params: IFeedBackItem) => {
  return (
    <div className="FeedBackItem">
      <div className="FeedBackItem__Header">
        <Image
          className="FeedBackItem__Header__image"
          // title="Icon"
          // alt="Icon"
          src={params.icon}
          webp={params.iconWeb}
        />
      </div>
      <div className="FeedBackItem__Footer">
        <noindex>
          <div className="FeedBackItem__Text">{params.text}</div>
        </noindex>
        <div className="FeedBackItem__Footer__Aut">
          {/* <img alt="" className="FeedBackItem__Icon" src={aut_icon}></img> */}
          <div className="FeedBackItem__Name">{params.author}</div>
          <div className="FeedBackItem__Post">{params.aut_post}</div>
          <Image
            className="FeedBackItem__FooterLine"
            title="FooterLine"
            alt="FooterLine"
            src={FooterLine}
            webp={FooterLineWeb}
          />
        </div>
      </div>
    </div>
  );
};
