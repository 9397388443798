import '../styles/AdvantagesGroup.css'

interface IAdvantagesGroupItem {
  icon: string,
  title: string,
  text: string,
}

export const AdvantagesGroupItem = ({ icon, title, text }: IAdvantagesGroupItem) => {
  return (
    <div className="AdvantagesGroupItem">
      <img src={icon} className="AdvantagesGroupItem__img" alt=""></img>
      <span className="AdvantagesGroupItem__Title">{title}</span>
      <p className="AdvantagesGroupItem__Text">{text}</p>
    </div>
  );
}