import React, { useEffect } from "react";
import "./Privacy.css";
// import "bootstrap/dist/css/bootstrap.css";

const Privacy = ({ setvisualPrivacy }: any) => {
  useEffect(() => {
    setvisualPrivacy(true);
  }, []);
  
  return (
    <div className="privacy-container">
      <div className="privacy-text">
        <p className="version">Версия от 18.04.2023</p>
        <div className="main-header">
          ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ВЕБ-САЙТА<br />  «Отдела разработки программного обеспечения .NET»
        </div>
        <h5>1. Термины и определения.</h5>
        <p>
          <p>
            1.1. Веб-сайт – совокупность графических и информационных
            материалов, а также программ для ЭВМ и баз данных, обеспечивающих их
            доступность в сети интернет по сетевому адресу{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>.
          </p>
          <p>
            1.2. Обезличивание персональных данных — действия, в результате
            которых невозможно определить без использования дополнительной
            информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных.
          </p>
          <p>
            1.3. Обработка персональных данных – любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </p>
          <p>
            1.4. Оператор – государственный орган, муниципальный орган,
            юридическое или физическое лицо, самостоятельно или совместно с
            другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными.
          </p>
          <p>
            1.5. Персональные данные – любая информация, относящаяся прямо или
            косвенно к определенному или определяемому Пользователю веб-сайта{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>.
          </p>
          <p>
            1.6. Пользователь – любой посетитель веб-сайта{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>.
          </p>
          <p>
            1.7. Предоставление персональных данных – действия, направленные на
            раскрытие персональных данных определенному лицу или определенному
            кругу лиц.
          </p>
        </p>
        <h5>2. Общие положения.</h5>
        <p>
          <p>
            2.1. Настоящая политика обработки персональных данных составлена в
            соответствии с требованиями Федерального закона от 27.07.2006.
            №152-ФЗ «О персональных данных» (далее - Закон о персональных
            данных) и определяет порядок обработки персональных данных и меры по
            обеспечению безопасности персональных данных, предпринимаемые ООО
            «АБС» (далее – Оператор).
          </p>
          <p>
            2.2. Использование Веб-сайта{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>{" "}
            означает безоговорочное согласие Пользователя с настоящей Политикой
            и указанными в ней условиями обработки информации, получаемой с
            устройства пользователя. В случае несогласия с Политикой
            пользователь должен воздержаться от использования Веб-сайта{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>.
          </p>
          <p>
            2.3. Приложение, товары и услуги в рамках Веб-сайта{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>{" "}
            реализуются пользователю на основании договоров и соглашений с
            Обществом, которые в числе прочего регулируют все вопросы обработки
            и хранения Обществом персональных данных Пользователя.
          </p>
          <p>
            2.4. Настоящая Политика применима только к Веб-сайту{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>.
            Общество не контролирует и не несет ответственность за информацию
            (последствия её передачи), переданную Пользователем третьей стороне,
            в случае если такая передача была выполнена на ресурсе третьей
            стороны, на который пользователь мог перейти по ссылкам из
            Приложения.
          </p>
          <p>
            2.5. Общество имеет право вносить изменения в настоящую Политику
            путем размещения новой редакции Политики на сайте Общества.
            Обязанность самостоятельного ознакомления с актуальной редакцией
            Политики лежит на Пользователе.
          </p>
        </p>
        <h5>3. Принципы и цели обработки персональных данных</h5>
        <p>
          <p>
            <p>
              3.1. Цели получения и обработки персональных данных Пользователя:
            </p>
            <p>
              - предоставление доступа Пользователю к сервисам, информации и/или
              материалам, содержащимся на веб-сайте https://www.dev-krabs.ru.
            </p>
            <p>
              - реализация обратной связи и осуществление взаимодействия с
              Оператором;
            </p>
            <p>- регистрация на сайтах Оператора;</p>
            <p>
              - подготовка к заключению договора с клиентом через сайт Оператора
              (формирование заявки на оказание услуги, выставление счёта,
              регистрация в личном кабинете и др.);
            </p>
            <p>
              - оказания услуг по технической поддержке клиентов через сайт
              Оператора.
            </p>
          </p>
          <p>
            3.2. Также Оператор имеет право направлять Пользователю уведомления
            о новых продуктах и услугах, специальных предложениях и различных
            событиях. Пользователь всегда может отказаться от получения
            информационных сообщений, направив Оператору письмо на адрес
            электронной почты{" "}
            <a href="mailto:mpsupport@kras-abs.ru">mpsupport@kras-abs.ru</a> с
            пометкой «Отказ от уведомлений о новых продуктах и услугах и
            специальных предложениях».
          </p>
          <p>
            3.3. Обезличенные данные Пользователей, собираемые с помощью
            сервисов интернет-статистики, служат для сбора информации о
            действиях Пользователей на сайте, улучшения качества сайта и его
            содержания.
          </p>
          <p>
            3.4. Обработка персональных данных осуществляется на законной
            основе.
          </p>
          <p>
            3.5. Обработка персональных данных ограничивается достижением
            конкретных, заранее определенных и законных целей. Не допускается
            обработка персональных данных, несовместимая с целями сбора
            персональных данных.
          </p>
          <p>
            3.6. Содержание и объем персональных данных соответствуют заявленным
            целям обработки. Обрабатываемые персональные данные не являются
            избыточным по отношению к заявленным целям обработки.
          </p>
          <p>
            3.7. Хранение персональных данных осуществляется в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных. Обрабатываемые персональные
            данные подлежат уничтожению либо обезличиванию по достижению целей
            обработки или в случае утраты необходимости в достижении этих целей,
            если иное не предусмотрено федеральным законом.
          </p>
        </p>
        <h5>
          4. Оператор может обрабатывать следующие персональные данные
          Пользователя
        </h5>
        <p>
          <p>
            4.1. Данные предоставленные Пользователем самостоятельно при
            пользовании веб-сайта{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a>{" "}
            включая, но, не ограничиваясь: имя, отчество, фамилия, пол, номер
            мобильного телефона, адрес электронной почты, название организации,
            в которой Пользователь работает и должность в этой организации.
          </p>
          <p>
            4.2. Также на веб-сайте происходит сбор и обработка обезличенных
            данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
            интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
          </p>
          <p>
            4.3. Иная информация о Пользователе, сбор и/или предоставление
            которой определено в Регулирующих документах отдельных Сервисов
            Оператора.
          </p>
        </p>
        <h5>5. Правовые основания обработки персональных данных</h5>
        <p>
          <p>
            <p>
              5.1. Правовыми основаниями обработки персональных данных
              Оператором являются:
            </p>
            <p>
              – Федеральный закон «Об информации, информационных технологиях и о
              защите информации» от 27.07.2006 N 149-ФЗ;
            </p>
            <p>– уставные документы Оператора;</p>
            <p>
              – договоры, заключаемые между оператором и субъектом персональных
              данных;
            </p>
            <p>
              – федеральные законы, иные нормативно-правовые акты в сфере защиты
              персональных данных;
            </p>
            <p>
              – согласия Пользователей на обработку их персональных данных, на
              обработку персональных данных, разрешенных для распространения.
            </p>
          </p>
          <p>
            5.2. Оператор обрабатывает персональные данные Пользователя только в
            случае их заполнения и/или отправки Пользователем самостоятельно
            через специальные формы, расположенные на сайте{" "}
            <a href="https://www.dev-krabs.ru">https://www.dev-krabs.ru</a> или
            направленные Оператору посредством электронной почты. Заполняя
            соответствующие формы и/или отправляя свои персональные данные
            Оператору, Пользователь выражает свое согласие с данной Политикой.
          </p>
          <p>
            5.3. Оператор обрабатывает обезличенные данные о Пользователе в
            случае, если это разрешено в настройках браузера Пользователя
            (включено сохранение файлов «cookie» и использование технологии
            JavaScript).
          </p>
          <p>
            5.4. Субъект персональных данных самостоятельно принимает решение о
            предоставлении его персональных данных и дает согласие свободно,
            своей волей и в своем интересе.
          </p>
        </p>
        <h5>6. Условия обработки персональных данных</h5>
        <p>
          <p>
            6.1. Обработка персональных данных осуществляется с согласия
            субъекта персональных данных на обработку его персональных данных.
          </p>
          <p>
            6.2. Обработка персональных данных необходима для исполнения
            договора, стороной которого либо выгодоприобретателем или
            поручителем по которому является субъект персональных данных, а
            также для заключения договора по инициативе субъекта персональных
            данных или договора, по которому субъект персональных данных будет
            являться выгодоприобретателем или поручителем.
          </p>
          <p>
            6.3. Обработка персональных данных необходима для осуществления прав
            и законных интересов оператора или третьих лиц либо для достижения
            общественно значимых целей при условии, что при этом не нарушаются
            права и свободы субъекта персональных данных.
          </p>
          <p>
            6.4. Осуществляется обработка персональных данных, подлежащих
            опубликованию или обязательному раскрытию в соответствии с
            федеральным законом.
          </p>
        </p>
        <h5>7. Основные права и обязанности Оператора</h5>
        <p>
          <p>
            <p>7.1.Оператор имеет право:</p>
            <p>
              – получать от субъекта персональных данных достоверные информацию
              и/или документы, содержащие персональные данные;
            </p>
            <p>
              – в случае отзыва субъектом персональных данных согласия на
              обработку персональных данных Оператор вправе продолжить обработку
              персональных данных без согласия субъекта персональных данных при
              наличии оснований, указанных в Законе о персональных данных;
            </p>
            <p>
              – самостоятельно определять состав и перечень мер, необходимых и
              достаточных для обеспечения выполнения обязанностей,
              предусмотренных Законом о персональных данных и принятыми в
              соответствии с ним нормативными правовыми актами, если иное не
              предусмотрено Законом о персональных данных или другими
              федеральными законами.
            </p>
          </p>
          <p>
            <p>7.2.Оператор обязан:</p>
            <p>
              – предоставлять субъекту персональных данных по его просьбе
              информацию, касающуюся обработки его персональных данных;
            </p>
            <p>
              – организовывать обработку персональных данных в порядке,
              установленном действующим законодательством РФ;
            </p>
            <p>
              – отвечать на обращения и запросы субъектов персональных данных и
              их законных представителей в соответствии с требованиями Закона о
              персональных данных;
            </p>
            <p>
              – сообщать в уполномоченный орган по защите прав субъектов
              персональных данных по запросу этого органа необходимую информацию
              в течение 30 дней с даты получения такого запроса;
            </p>
            <p>
              – публиковать или иным образом обеспечивать неограниченный доступ
              к настоящей Политике в отношении обработки персональных данных;
            </p>
            <p>
              – принимать правовые, организационные и технические меры для
              защиты персональных данных от неправомерного или случайного
              доступа к ним, уничтожения, изменения, блокирования, копирования,
              предоставления, распространения персональных данных, а также от
              иных неправомерных действий в отношении персональных данных;
            </p>
            <p>
              – прекратить передачу (распространение, предоставление, доступ)
              персональных данных, прекратить обработку и уничтожить
              персональные данные в порядке и случаях, предусмотренных Законом о
              персональных данных;
            </p>
            <p>
              – исполнять иные обязанности, предусмотренные Законом о
              персональных данных.
            </p>
          </p>
        </p>
        <h5>8. Основные права субъектов персональных данных</h5>
        <p>
          <p>
            <p>8.1.Субъекты персональных данных имеют право:</p>
            <p>
              – получать информацию, касающуюся обработки его персональных
              данных, за исключением случаев, предусмотренных федеральными
              законами. Сведения предоставляются субъекту персональных данных
              Оператором в доступной форме, и в них не должны содержаться
              персональные данные, относящиеся к другим субъектам персональных
              данных, за исключением случаев, когда имеются законные основания
              для раскрытия таких персональных данных. Перечень информации и
              порядок ее получения установлен Законом о персональных данных;
            </p>
            <p>
              – требовать от оператора уточнения его персональных данных, их
              блокирования или уничтожения в случае, если персональные данные
              являются неполными, устаревшими, неточными, незаконно полученными
              или не являются необходимыми для заявленной цели обработки, а
              также принимать предусмотренные законом меры по защите своих прав;
            </p>
            <p>
              – выдвигать условие предварительного согласия при обработке
              персональных данных в целях продвижения на рынке товаров, работ и
              услуг;
            </p>
            <p>– на отзыв согласия на обработку персональных данных;</p>
            <p>
              – обжаловать в уполномоченный орган по защите прав субъектов
              персональных данных или в судебном порядке неправомерные действия
              или бездействие Оператора при обработке его персональных данных;
            </p>
            <p>
              – на осуществление иных прав, предусмотренных законодательством
              РФ.
            </p>
          </p>
        </p>
        <h5>
          9. Порядок сбора, хранения, передачи и других видов обработки
          персональных данных
        </h5>
        <p>
          <p>
            Безопасность персональных данных, которые обрабатываются Оператором,
            обеспечивается путем реализации правовых, организационных и
            технических мер, необходимых для выполнения в полном объеме
            требований действующего законодательства в области защиты
            персональных данных.
          </p>
          <p>
            9.1. Оператор обеспечивает сохранность персональных данных и
            принимает все возможные меры, исключающие доступ к персональным
            данным неуполномоченных лиц.
          </p>
          <p>
            9.2. Персональные данные Пользователя никогда, ни при каких условиях
            не будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства либо в случае, если
            субъектом персональных данных дано согласие Оператору на передачу
            данных третьему лицу для исполнения обязательств по
            гражданско-правовому договору.
          </p>
          <p>
            9.3. В случае выявления неточностей в персональных данных,
            Пользователь может актуализировать их самостоятельно, путем
            направления Оператору уведомление на адрес электронной почты
            Оператора{" "}
            <a href="mailto:mpsupport@kras-abs.ru">mpsupport@kras-abs.ru</a> с
            пометкой «Актуализация персональных данных».
          </p>
          <p>
            9.4. Срок обработки персональных данных определяется достижением
            целей, для которых были собраны персональные данные, если иной срок
            не предусмотрен договором или действующим законодательством.
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив Оператору уведомление
            посредством электронной почты на электронный адрес Оператора{" "}
            <a href="mailto:mpsupport@kras-abs.ru">mpsupport@kras-abs.ru</a> с
            пометкой «Отзыв согласия на обработку персональных данных».
          </p>
        </p>
        <h5>
          10. Перечень действий, производимых Оператором с полученными
          персональными данными
        </h5>
        <p>
          <p>
            10.1. Оператор осуществляет сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление и уничтожение персональных
            данных.
          </p>
          <p>
            10.2. Оператор осуществляет автоматизированную обработку
            персональных данных с получением и/или передачей полученной
            информации по информационно-телекоммуникационным сетям или без
            таковой.
          </p>
        </p>
        <h5>11. Конфиденциальность персональных данных</h5>
        <p>
          <p>
            Оператор и иные лица, получившие доступ к персональным данным,
            обязаны не раскрывать третьим лицам и не распространять персональные
            данные без согласия субъекта персональных данных, если иное не
            предусмотрено федеральным законом.
          </p>
        </p>
        <h5>12. Заключительные положения</h5>
        <p>
          <p>
            12.1. Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            к Оператору с помощью электронной почты{" "}
            <a href="mailto:mpsupport@kras-abs.ru">mpsupport@kras-abs.ru</a>.
          </p>
          <p>
            12.2. В данном документе будут отражены любые изменения политики
            обработки персональных данных Оператором. Политика действует
            бессрочно до замены ее новой версией.
          </p>
          <p>
            12.3. Актуальная версия Политики в свободном доступе расположена в
            сети Интернет по адресу{" "}
            <a href="https://www.dev-krabs.ru/privacy">
              https://www.dev-krabs.ru/privacy
            </a>
            .
          </p>
        </p>
      </div>
    </div>
  );
};

export default Privacy;
