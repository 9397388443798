import { useStore } from "effector-react";
import { AdvantagesGroup } from "../molecules/AdvantagesGroup";
import { ClientFeedback } from "../molecules/ClientFeedback";
import { ClientLine } from "../molecules/ClientsLine";
import { ConsultationRequest } from "../molecules/ConsultationRequest";
import { FreeConsultationSign } from "../molecules/FreeConsultationSign";
import { ProjectCasesNew } from "../molecules/ProjectCasesNew";
import { UsingTechnologiesGroup } from "../molecules/UsingTechnologies";

import { $feedBackList } from "../logics/ClientFeedbackLoad";
import { $clientList } from "../logics/ClientsLineLoad";
import { $technologyList } from "../logics/UsingTechnologyItemLogic";
import { useEffect } from "react";
import { setContactsButtonVisual } from "../../../App";
import { Helmet } from "react-helmet";
import { WeOffer } from "../molecules/WeOffer";

export const MainPage = () => {
  const technologyList = useStore($technologyList);
  const clientList = useStore($clientList);
  const feedBackList = useStore($feedBackList);

  useEffect(() => {
    setContactsButtonVisual(true);
  }, []);
  return (
    <>
       <Helmet>
        <title>АБС - IT Компания</title>
        <meta
          name="description"
          content="Разработка современных мобильных приложений. Мы поможем вам улучшить бизнес!"
        />
      </Helmet>
      <FreeConsultationSign />
      <WeOffer/>
      <AdvantagesGroup />
      <ConsultationRequest />
      <UsingTechnologiesGroup list={technologyList} />
      <ProjectCasesNew />
      <ClientLine list={clientList} />
      <ClientFeedback list={feedBackList} />
    </>
  );
};
