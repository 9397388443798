import { NavLink } from "react-router-dom";
import "../styles/OrangeButton.css";
export interface IOrangeButton {
  title?: string;
  className?: string;
  link?: string;
  onCLick?: () => void;
}

export const OrangeButton = (params: IOrangeButton) => {
  return params.link ? (
    <NavLink className={params.className + " OrangeButton"} to={params.link}>
      {params.title || "Кнопка"}
    </NavLink>
  ) : (
    <button
      onClick={params.onCLick}
      className={params.className + " OrangeButton"}
    >
      {params.title || "Кнопка"}
    </button>
  );
};
