import '../Styles/JobsText.css'

export const JobsText = () => {
    return (
        <div className="JobsText">
            <span>Вакансии</span>
            <p>
                АБС - это IT-компания с командой профессионалов.<br />
                Более 20 лет на рынке. Мы развиваем сотрудничество с Microsoft, среди наших клиентов значимые компании Красноярского края, крупные холдинги.<br />
                <br />
                В лице компании вы получите надежного работодателя, который объединил в команду профессионалов.<br />
                <br />
                Молодым и амбициозным мы предлагаем обучение у лучших наставников-экспертов, надежную базу для старта, карьерный рост и профессиональное развитие.<br />
                Растите, повышайте профессионализм, реализуйте амбиции и заводите друзей в кругу активных и целеустремленных людей.<br />
            </p>
        </div>
    )
}