import { YMaps, Map, Placemark, ZoomControl } from "@pbe/react-yandex-maps"
import MapMark from '../../../common/assets/Contacts/MapMark.svg'


export const MapAtom = () => {
    return (
        <div className="MapAtom">
            <YMaps>
                <Map defaultState={{
                    center: [56.032464, 92.913945],
                    zoom: 16,
                }}
                    width="100%"
                    height="382px">
                    <ZoomControl  />
                    <Placemark geometry={[56.032464, 92.913945]} options={{
                        iconLayout: "default#image",
                        iconImageSize: [185, 68],
                        iconImageHref: MapMark,
                        iconImageOffset: [-29, -65],
                    }} />

                </Map>
            </YMaps>
        </div>


    )
}