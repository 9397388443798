
export const pathPrefix: string = ""
export const pathMain: string = pathPrefix + "/"
export const pathPortfolio: string = pathPrefix + "/portfolio"
export const pathContacts: string = pathPrefix + "/contacts"
export const pathJobs: string = pathPrefix + "/jobs"
export const pathprivacy: string = pathPrefix + "/privacy"

export const pathList: string[] = [pathMain,pathPortfolio,pathContacts,pathJobs]
export const namePathMain: string = "Главная"
export const namePathPortfolio: string = "Портфолио"
export const namePathContacts: string = "Контакты"
export const namePathJobs: string = "Вакансии"