import "../styles/ConsultationRequest.css";
import { OrangeButton } from "../../../common/ui/OrangeButton/organoids/OrangeButton";
import { pathContacts } from "../../../common/config/path";

export const ConsultationRequest = () => {
  return (
    <div className="ConsultationRequest" id="referense">
      <div className="ConsultationRequest__Title">
        <span>
          У вас есть <span style={{ color: "coral" }}>Проект </span>или{" "}
          <span style={{ color: "coral" }}>Идея</span>?
        </span>
      </div>
      <div className="ConsultationRequest__TextAndForm">
        <span className="ConsultationRequest__Text">
          Закажите{" "}
          <span className="ConsultationRequest__Text__Coral">
            бесплатную консультацию
          </span>
          , и мы обязательно ответим на все интересующие вас вопросы!
        </span>
        <div className="ConsultationRequest__Form">
          {/* <input className="ConsultationRequest__Form__Name" placeholder="Укажите ваше Имя">
                </input>
                <input className="ConsultationRequest__Form__Mail" placeholder="Укажите ваш Email или номер телефона">
                </input> */}
          <OrangeButton
            className={"ConsultationRequest__Form__Button"}
            link={pathContacts}
            title="Оставить заявку"
          />
        </div>
      </div>
    </div>
  );
};
