import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/montserrat";
import "@fontsource/roboto";
import "@fontsource/poppins";
import { Route, Routes, useLocation } from "react-router-dom";
import { useStore } from "effector-react";
import { useEffect, useState } from "react";
import { createEvent, createStore } from "effector";
import { ToastContainer } from "react-toastify";

import { MainPage } from "./pages/MainPage/organoids/MainPage";
import { ContactsPage } from "./pages/Contacts/organoids/ContactsPage";
import { Jobs } from "./pages/Jobs/Organoids/Jobs";
import { Portfolio } from "./pages/Portfolio/organoids/Portfolio";
import { $ProjectsLists } from "./common/Projects/ProjectsLists/ProjectsLists";
import { ProjectsItem } from "./common/Projects/Organoids/ProjectsItem";
import { Header } from "./common/ui/Header/organoids/Header";
import { Footer } from "./common/ui/Footer/organoids/Footer";
import {
  pathPortfolio,
  pathMain,
  pathJobs,
  pathContacts,
  pathprivacy,
} from "./common/config/path";
import Privacy from "./common/ui/Privacy/Privacy";
import CookieAlert from "./common/ui/CookieAlert/CookieAlert";
import SideAnimation from "./common/ui/SideAnimation/Organoids/SideAnimation";
import NotFound from "./pages/ErrorPages/404";

export const $ContactsButtonVisual = createStore<any>(true);
export const setContactsButtonVisual = createEvent<any>();
$ContactsButtonVisual.on(setContactsButtonVisual, (_, val) => val);

function App() {
  const ProjectsLists = useStore($ProjectsLists);
  const ContactsButtonVisual = useStore($ContactsButtonVisual);
  const location = useLocation();

  const [visualCookieAlert, setVisualCookieAlert] = useState(true);
  const [visualPrivacy, setvisualPrivacy] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("CookieAlert") &&
      localStorage.getItem("CookieAlert") === "1"
    ) {
      setVisualCookieAlert(false);
    }
  }, []);

  return (
    <div className={!visualPrivacy ? "App" : "App App__bacgroundToPrivacy"}>
      {(!localStorage.getItem("CookieAlert") ||
        localStorage.getItem("CookieAlert") !== "1") && (
        <CookieAlert
          visualCookieAlert={visualCookieAlert}
          setVisualCookieAlert={setVisualCookieAlert}
        />
      )}
      {!visualPrivacy && (
        <Header
          ContactsButtonVisual={ContactsButtonVisual}
          setContactsButtonVisual={setContactsButtonVisual}
        />
      )}
      {location.pathname !== "/privacy" && <SideAnimation />}
      <div className="App__Actual">
        <Routes>
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route path={pathMain} element={<MainPage />} />
          <Route path={pathJobs} element={<Jobs />} />
          <Route path={pathPortfolio} element={<Portfolio />} />
          <Route path={pathContacts} element={<ContactsPage />} />
          <Route
            path={pathprivacy}
            element={<Privacy setvisualPrivacy={setvisualPrivacy} />}
          />
          {ProjectsLists.map((e: any, id: any) => (
            <Route
              path={pathPortfolio + "/" + e.url}
              key={id}
              element={
                <ProjectsItem
                  title={e.long.title}
                  image={e.long.image}
                  about={e.long.about}
                  task={e.long.task}
                  technologies={e.long.technologies}
                  client={e.long.client}
                  result={e.long.result}
                  app={e.long.app}
                  imageWeb={e.long.imageWeb}
                />
              }
            />
          ))}
        </Routes>
      </div>
      {!visualPrivacy && <Footer />}
      <ToastContainer />
    </div>
  );
}

export default App;
