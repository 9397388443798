import LeftBlow from "../Assets/LeftBlow.svg";
import RightBlow from "../Assets/RightBlow.svg";
import { useState, useEffect } from "react";
import "../Styles/SideAnimation.css";

export const SideAnimation = () => {
  const [start, setStart] = useState(true);
  const [blowleft, setBlowLeft] = useState("leftSideBlowFly");
  const [blowright, setBlowRight] = useState("RightSideBlowFly");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setBlowLeft(position === 0 ? "leftSideBlow" : "leftSideBlowFly");
    setBlowRight(position === 0 ? "RightSideBlow" : "RightSideBlowFly");
  };

  if (start === true) {
    handleScroll();
    setStart(false);
  }

  return (
    <>
      <img className={blowleft} src={LeftBlow} alt="" />
      <div className="hideblow">
        <img className={blowright} src={RightBlow} alt="" />
      </div>
    </>
  );
};
export default SideAnimation;
