interface IPortfolioTitle {
    text: string
}
export const PortfolioTitle = (params: IPortfolioTitle) => {
    return (
        <div className="PortfolioTitle">
            {params.text}
        </div>
    );
}
