import Calendar from "../../../common/assets/MainPage/AdvantagesGroup/calendar 1.svg"
import Mobile_app from "../../../common/assets/MainPage/AdvantagesGroup/mobile-app 1.svg"
import Server from "../../../common/assets/MainPage/AdvantagesGroup/server 1.svg"

import { AdvantagesGroupItem } from "../atoms/AdvantagesGroupItem";
import "../styles/AdvantagesGroup.css"

export const AdvantagesGroup = () => {
  return (
    <div className="AdvantagesGroup">
        <span className="AdvantagesGroup__Title">Почему мы?</span>
        <div className="AdvantagesGroup__Items ">
            <AdvantagesGroupItem icon={Server} title="Сервер" text="Имеем большой опыт в проектировании серверной части для проектов наших клиентов." />
            <AdvantagesGroupItem icon={Mobile_app} title="Приложения" text="Слышим клиента, делаем то, что именно ему необходимо." />
            <AdvantagesGroupItem icon={Calendar} title="Сроки" text="Делаем все, чтобы клиент получил свой проект в срок. Работаем по Scrum." />
        </div>
    </div>
  );
}
