import { NavLink } from "react-router-dom";
import { pathprivacy } from "../../../config/path";
import { contactUsEmail, contactUsTel } from "../../../config/vars";
import "../styles/Footer.css";

const comInfo =
  "© IT компания «АБС» 2000-" +
  new Date().getFullYear() +
  " г. Все права защищены.";

export const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer__Contact">
        <a href={"tel:" + contactUsTel}>{contactUsTel}</a>
        {contactUsEmail}
      </div>

      <NavLink
        target="_blank"
        to={pathprivacy}
        className="Footer__Info"
        style={{ color: "aliceblue" }}
      >
        Политика конфиденциальности
      </NavLink>
      <span className="Footer__Info">{comInfo}</span>
    </div>
  );
};
