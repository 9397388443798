

export const mainColor: string = "#E07D34"

export const OrgNameABS = "ООО АБС"

export const contactUsTel: string = "+ 7 (902) 926-88-24"
export const contactUsEmail: string = "mpsupport@kras-abs.ru"

export const contactAdress: string = "660135 г. Красноярск ул. Взлётная, 7а, офис 164"
export const contactTel: string = "+ 7 (902) 926-88-24"
export const contactEmail: string = "mpsupport@kras-abs.ru"

export const contactComTel: string = "+ 7 (391) 292-97-23"
export const contactComEmail: string = "opr@kras-abs.ru"

export const IMAGES_URL: string = "https://kras-abs.ru/" 