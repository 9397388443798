import { createEvent, restore } from "effector";
import Company1 from '../../../common/assets/MainPage/Companies/Company1.png'
import Company1_1 from '../../../common/assets/MainPage/Companies/Company1_1.png'
import Company2 from '../../../common/assets/MainPage/Companies/Company2.png'
import Company2_1 from '../../../common/assets/MainPage/Companies/Company2_1.png'
import Company3 from '../../../common/assets/MainPage/Companies/Company3.png'
import Company3_1 from '../../../common/assets/MainPage/Companies/Company3_1.png'
import Company4 from '../../../common/assets/MainPage/Companies/Company4.png'
import Company4_1 from '../../../common/assets/MainPage/Companies/Company4_1.png'

import Company1Web from '../../../common/assets/MainPage/Companies/Company1.webp'
import Company1_1Web from '../../../common/assets/MainPage/Companies/Company1_1.webp'
import Company2Web from '../../../common/assets/MainPage/Companies/Company2.webp'
import Company2_1Web from '../../../common/assets/MainPage/Companies/Company2_1.webp'
import Company3Web from '../../../common/assets/MainPage/Companies/Company3.webp'
import Company3_1Web from '../../../common/assets/MainPage/Companies/Company3_1.webp'
import Company4Web from '../../../common/assets/MainPage/Companies/Company4.webp'
import Company4_1Web from '../../../common/assets/MainPage/Companies/Company4_1.webp'

export interface IClientLineItem {
  icon: string,
  iconWeb: string,
  iconcolored: string,
  iconcoloredWeb: string,
  url:string
}

export const setClientList = createEvent<IClientLineItem[]>();
export const $clientList = restore<IClientLineItem[]>(
  setClientList,
  [{ icon: Company1, iconWeb: Company1Web, iconcoloredWeb: Company1_1Web, iconcolored: Company1_1, url:"https://comfort-group.ru/" },
  { icon: Company2, iconWeb: Company2Web, iconcoloredWeb: Company2_1Web, iconcolored: Company2_1, url:"https://kras-tko.ru/" },
  { icon: Company3, iconWeb: Company3Web, iconcoloredWeb: Company3_1Web, iconcolored: Company3_1, url:"http://di-soft.ru/" },
  { icon: Company4, iconWeb: Company4Web, iconcoloredWeb: Company4_1Web, iconcolored: Company4_1, url:"https://www.krasplat.ru/" }]
);