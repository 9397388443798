import { NavLink } from "react-router-dom";
import { IHeaderNavList } from "../logics/effector";
import Burger from "../../../assets/Header/Burger.svg";
import ABSLogo from "../../../assets/Header/ABSLogo.svg";
import Clouse from "../../../assets/Header/Clouse.svg";

import { useState } from "react";
import "../styles/MenuBar.css";
import { pathContacts, pathMain } from "../../../config/path";
import { OrangeButton } from "../../OrangeButton/organoids/OrangeButton";
interface IMenuBart {
  list: IHeaderNavList[];
}

export const MenuBar = ({ list }: IMenuBart) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <img
        onClick={() => {
          setActive(!active);
        }}
        className="MenuBar__Burger"
        src={Burger}
        alt="Burger"
      />
      {active && (
        <div className="MenuBar">
          <div className="MenuBar__Active">
            <div className="MenuBar__Active__Header">
              <NavLink to={pathMain}>
                <img
                  src={ABSLogo}
                  className="MenuBar__Active__Logo"
                  alt="Logo"
                ></img>
              </NavLink>
              <img
                src={Clouse}
                onClick={() => {
                  setActive(!active);
                }}
                className="MenuBar__Active__Header__Clouse"
                alt="Clouse"
              />
            </div>
            {list.map((e, i) => (
              <NavLink
                to={e.path}
                onClick={() => {
                  setActive(!active);
                }}
                className={(navData: any) =>
                  navData.isActive
                    ? "MenuBar__Nav__style-active MenuBar__Nav"
                    : "MenuBar__Nav"
                }
                key={i}
              >
                {e.name}
              </NavLink>
            ))}
            <OrangeButton
              className="MenuBar__Active__Header__OrangeButton"
              link={pathContacts}
              title="Оставить заявку"
            />
          </div>
        </div>
      )}
    </>
  );
};
