import '../styles/UsingTechnologiesGroup.css'
import { IUsingTechnologyItem } from '../logics/UsingTechnologyItemLogic'
import { useWindowWidth } from '@react-hook/window-size'
import Image from "react-image-webp";

interface ITechList {
    list: IUsingTechnologyItem[]
}
export const UsingTechnologiesGroup = ({ list }: ITechList) => {
    const onlyWidth = useWindowWidth()
    return (
        <div className="UsingTechnologiesGroup">
            <div className="UsingTechnologiesGroup__Text">Используем самые передовые технологии для воплощения идей клиента</div>
            <div className="UsingTechnologiesGroup__Items">
                {list.map((e, i) => (
                    <div className="UsingTechnologiesGroup__Items__Item" key={i}>
                        <Image
                            className="UsingTechnologiesGroup__Items__Item__Icon"
                            title={e.title}
                            alt={e.title}
                            src={e.icon}
                            webp={e.iconWeb}
                        />
                    </div>
                ))}
            </div>
            <div style={{ width: `${onlyWidth}px` }} className="UsingTechnologiesGroup__Background"></div>
        </div>
    )
}