import Image from "react-image-webp";
export interface IOfferItem {
    icon: string,
    iconWeb: string,
    text: string
}

export const OfferItem = (params: IOfferItem) => {
    return (
        <div className="OfferItem">
            <Image
                className="OfferItem__Img"
                title={params.text}
                alt={params.text}
                src={params.icon}
                webp={params.iconWeb}
            />
            <span className="OfferItem__Text" >{params.text}</span>
        </div>
    )
}