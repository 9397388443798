import { ClientLineItem } from '../atoms/ClientLineItem'
import { IClientLineItem } from '../logics/ClientsLineLoad'
import '../styles/ClientLine.css'

interface IClientsList {
    list: IClientLineItem[]
}
export const ClientLine = (params: IClientsList) => {
    return (
        <div className="ClientLine">
            <span>Почему клиенты доверяют нам?</span>
            <div className="ClientLine__Group">
                {params.list.map((e, i) => (
                    <ClientLineItem key={i} icon={e.icon} iconWeb={e.iconWeb} iconcolored={e.iconcolored} iconcoloredWeb={e.iconcoloredWeb} url={e.url}></ClientLineItem>
                ))}
            </div>
        </div>
    )
}