import IconPhone from '../../../common/assets/MainPage/ClientFeedbackLoad/IconPhone.svg'
import { pathContacts } from '../../../common/config/path'
import { OrangeButton } from '../../../common/ui/OrangeButton/organoids/OrangeButton'
import '../styles/FreeConsultationSign.css'

export const FreeConsultationSign = () => {

    return (
        <div className="ConsultationRowSign">
            <div className="ConsultationRowSign__Info">
                <div className="ConsultationRowSign__Info__Text">
                    <p className="ConsultationRowSign__Info__Text__Big">Разработка современных мобильных приложений</p>
                    <p className="ConsultationRowSign__Info__Text__Small">Мы поможем Вам улучшить бизнес</p>
                    <OrangeButton link={pathContacts} className="ConsultationRowSign__Info__Text__Button" title='Бесплатная консультация' />
                </div>
                <img  className="ConsultationRowSign__Info__Gradient__Phone" src={IconPhone} alt="" />
            </div>
        </div>
    )
}