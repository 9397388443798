import { useState } from "react";
import { querySendMail } from "../../../common/sendMail";
import { OrangeButton } from "../../../common/ui/OrangeButton/organoids/OrangeButton";
import { toast } from "react-toastify";

export const RequestAtom = () => {
  const [value, setValue] = useState({ contact: "", name: "", text: "" });

  const sendRequestAtom = () => {
    let errorText = "Не введены обязательные поля: ";
    if (value.contact.length === 0) {
      errorText += " Номер телефона";
    }
    if (value.name.length === 0) {
      errorText += " Имя";
    }
    if (value.contact.length === 0 || value.name.length === 0)
      toast.error(errorText);

    if (value.contact.length !== 0 && value.name.length !== 0) {
      querySendMail({
        contact: value.contact,
        name: value.name,
        text: value.text,
      });
    }
  };

  const ChangePhoneEdit = (event: any) => {
    let val = event.target.value;
    val = val.replace(/ /gm, "").replace(/[^+\d]/g, "");
    console.log(val);

    let num = "";

    if (val[0] == 8) {
      num = `${val.substring(0, 1)} ${val.substring(1, 4)} ${val.substring(
        4,
        7
      )} ${val.substring(7, 9)} ${val.substring(9, 11)}`;
    } else if (val[0] == 7) {
      num = `+ ${val.substring(0, 1)} ${val.substring(1, 3)} ${val.substring(
        3,
        6
      )} ${val.substring(6, 9)} ${val.substring(9, 11)} ${val.substring(
        11,
        13
      )}`;
    } else if (val[0] == "+") {
      num = `${val.substring(0, 1)} ${val.substring(1, 2)} ${val.substring(
        2,
        5
      )} ${val.substring(5, 8)} ${val.substring(8, 10)} ${val.substring(
        10,
        12
      )}`;
    }
    //  else if (val[0] == 2) {
    //   num = `${val.substring(0, 1)} ${val.substring(1,3)} ${val.substring(3,5)} ${val.substring(5,7)}`;

    // } else if (val.length===6) {
    //   num = `${val.substring(0,2)} ${val.substring(2,4)} ${val.substring(4,6)}`;

    // }
    else {
      num = val;
    }

    num = num.trim();

    setValue({ ...value, ["contact"]: num });
  };

  const ChangeNameEdit = (event: any) => {
    let val = event.target.value;
    val = val.replace(/[^a-zа-яё0-9\s]/gi, "").replace(/[0-9]/g, "");
    console.log(val);
    setValue({ ...value, ["name"]: val });
  };
  return (
    <div className="RequestAtom">
      <span>
        Закажите{" "}
        <span className="RequestAtom__Coral">бесплатную консультацию</span>, и
        мы обязательно ответим на все интересующие вас вопросы!
      </span>
      <div className="RequestAtom__Block">
        <input
          value={value.name}
          onChange={ChangeNameEdit}
          className="RequestAtom__Block__Name RequestAtom__Block__Input"
          placeholder="Укажите Ваше Имя*"
        />
        <input
          value={value.contact}
          onChange={ChangePhoneEdit}
          className="RequestAtom__Block__Phone RequestAtom__Block__Input"
          placeholder="Укажите ваш телефон*"
        />
        <textarea
          onChange={(event) =>
            setValue({ ...value, ["text"]: event.target.value })
          }
          className="RequestAtom__Block__Text RequestAtom__Block__Input"
          placeholder="Текст сообщения"
        />
        <div className="RequestAtom__Block__Button">
          <OrangeButton
            onCLick={sendRequestAtom}
            className={"RequestAtom__Block__Button__Style"}
            title="Отправить"
          />
        </div>
      </div>
    </div>
  );
};
