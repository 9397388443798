import Calendar from '../../../common/assets/Jobs/OfferLoad/calendar.png'
import High from '../../../common/assets/Jobs/OfferLoad/high 1.png'
import Income from '../../../common/assets/Jobs/OfferLoad/income.png'
import Professional from '../../../common/assets/Jobs/OfferLoad/professional.png'
import Shedule from '../../../common/assets/Jobs/OfferLoad/schedule 1.png'
import Stamp from '../../../common/assets/Jobs/OfferLoad/stamp.png'

import CalendarWeb from '../../../common/assets/Jobs/OfferLoad/calendar.webp'
import HighWeb from '../../../common/assets/Jobs/OfferLoad/high 1.webp'
import IncomeWeb from '../../../common/assets/Jobs/OfferLoad/income.webp'
import ProfessionalWeb from '../../../common/assets/Jobs/OfferLoad/professional.webp'
import SheduleWeb from '../../../common/assets/Jobs/OfferLoad/schedule 1.webp'
import StampWeb from '../../../common/assets/Jobs/OfferLoad/stamp.webp'
import { createEvent, restore } from "effector";
import { IOfferItem } from "../Atoms/OfferItem";

export const setOfferList = createEvent<IOfferItem[]>();
export const $offerList = restore<IOfferItem[]>(
  setOfferList,
  [{ icon: Professional, iconWeb: ProfessionalWeb, text: "Профессиональный рост" },
  { icon: Stamp, iconWeb: StampWeb, text: "Трудоустройство в соответствии с ТК РФ с первого дня" },
  { icon: Calendar, iconWeb: CalendarWeb, text: "Заработная плата выплачивается 2 раза в месяц" },
  { icon: Income, iconWeb: IncomeWeb, text: "Стабильная и конкурентная заработная плата (по итогам собеседования)" },
  { icon: High, iconWeb: HighWeb, text: "Возможность не только работать и зарабатывать, но и каждый день развивать себя, как профессионала!" },
  { icon: Shedule, iconWeb: SheduleWeb, text: "График работы: понедельник – пятница, с 9:00 до 18:00" }
  ]
);