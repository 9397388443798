import Image from "react-image-webp";
import pin from "../../../common/assets/Contacts/pin 1.png";
import phone from "../../../common/assets/Contacts/phone 1.png";
import message from "../../../common/assets/Contacts/message 1.png";

import pinWeb from "../../../common/assets/Contacts/pin 1.webp";
import phoneWeb from "../../../common/assets/Contacts/phone 1.webp";
import messageWeb from "../../../common/assets/Contacts/message 1.webp";

import {
  contactAdress,
  contactComEmail,
  contactComTel,
  contactEmail,
  contactTel,
} from "../../../common/config/vars";

export const ContactsAtom = () => {
  return (
    <div className="ContactsAtom">
      <span className="ContactsAtom__Title">Контактная информация:</span>
      <div className="ContactsAtom__Info">
        <div className="ContactsAtom__Info__Item">
          <Image title="Адрес" alt="Адрес" src={pin} webp={pinWeb} />
          <span>{contactAdress}</span>
        </div>
        <div className="ContactsAtom__Info__Item">
        <a href={"tel:"+contactTel}> <Image title="Телефон" alt="Телефон" src={phone} webp={phoneWeb} />
          <span>{contactTel}</span></a>
        </div>
        <div className="ContactsAtom__Info__Item">
        <a href={"mailto:"+contactEmail}><Image title="Почта" alt="Почта" src={message} webp={messageWeb} />
          <span>{contactEmail}</span></a>
        </div>
      </div>
      {/* <span className="ContactsAtom__Title ContactsAtom__Title__Style">Коммерческий отдел:</span>
            <div className='ContactsAtom__Info'>
                <div className='ContactsAtom__Info__Item'>
                    <img src={phone} alt="" />
                    <span>{contactComTel}</span>
                </div>
                <div className='ContactsAtom__Info__Item'>
                    <img src={message} alt="" />
                    <span>{contactComEmail}</span>
                </div>
            </div> */}
    </div>
  );
};
