import { NavLink } from "react-router-dom";
import { IHeaderNavList } from "../logics/effector";

interface IHNavList {
  list: IHeaderNavList[];
}

export const HeaderNavList = ({ list }: IHNavList) => {
  return (
    <div className="Header__Nav">
      {list.map((e, i) => (
        <NavLink
          to={e.path}
          className={(navData: any) =>
            navData.isActive ? "Header__Nav__style-active" : ""
          }
          key={i}
        >
          {e.name}
        </NavLink>
      ))}
    </div>
  );
};
