import { useEffect } from "react";
import { MapAtom } from "../atoms/MapAtom";
import { OurContacts } from "../molecules/OurContacts";
import "../Styles/ContactsPage.css";
import { setContactsButtonVisual } from "../../../App";
import { Helmet } from "react-helmet";

export const ContactsPage = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  useEffect(() => {
    setContactsButtonVisual(false);
  }, []);
  return (
    <div className="ContactsPage">
       <Helmet>
        <title>АБС - Контакты</title>
        <meta
          name="description"
          content="Разработка современных мобильных приложений. 
          Закажите бесплатную консультацию, и мы обязательно ответим на все интересующие вас вопросы!"
        />
      </Helmet>
      <span>Наши контакты</span>
      <OurContacts />
      <MapAtom />
    </div>
  );
};
